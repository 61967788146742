<template>
  <App
    title="勘测单"
    right-text="创建勘测单"
    left-arrow
    @click-right="onClickRight"
  >
    <List
      v-model="loading"
      :finished="finished"
      :error.sync="error"
      :finished-text="total === 0 ? '暂无数据!' : 'No more items.!'"
      error-text="数据加载失败! 点击重试"
      @load="onLoad"
    >
      <Card
        class="list"
        v-for="$e in dataList"
        :key="$e.no"
      >
        <div
          class="card-info"
          slot="title"
        >
          <h3 class="name">No.{{$e.installOrderNo}}
            <a v-clipboard:copy="$e.installOrderNo"
              v-clipboard:success="onCopy"
              href="javascript:;"
              class="link-text"
            >复制</a>
          </h3>
          <span class="tag">{{$e.status}}</span>
        </div>
        <div class="info">
          <div>预约勘测时间：{{$e.surveyVisitTime}}</div>
          <div>服务明星：{{$e.serviceStarName}}</div>
          <div>服务明星手机号：{{$e.serviceStarTel}}</div>
          <div>创建时间：{{$e.createTime}}</div>
        </div>
      </Card>
    </List>
  </App>
</template>
<script>
// eslint-disable-next-line
import { List, Toast } from 'vant'
import Card from '_c/card'
import { OrderMessage } from '@/api/clue'
import { surveyStatusMap } from '@/utils/config'

export default {
  name: 'surveyOrder',
  // eslint-disable-next-line
  components: { Card, List },
  data () {
    return {
      loading: false,
      finished: false,
      error: false,
      total: 0,
      page: {
        index: 1,
        size: 10
      },
      dataList: [],
      orderInfo: {},
      suvOrderNumList: [], // 勘测单列表的订单号集合
    }
  },
  methods: {
    onCopy () {
      Toast('复制成功')
    },
    onLoad () {
      if (this.finished) return
      this.orderInfo = this.$route.query
      this.getSurveryOrderList()
    },
    onClickRight () {
      if (this.suvOrderNumList.includes(this.orderInfo.orderNum)) {
        Toast('该订单已经创建勘测单')
      } else {
        this.$router.push({
          path: '/createSurveyOrder',
          query: {
            orderNum: this.orderInfo.orderNum,
            uid: this.orderInfo.uid,
            source: 'order'
          }
        })
      }
    },
    getSurveryOrderList () {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      })
      const params = {
        pageNum: this.page.index,
        pageSize: this.page.size,
        orderNo: this.orderInfo.orderNum
      }
      OrderMessage(params).then(res => {
        Toast.clear()
        if (res.success) {
          const dataList = res.data.list.map(e => {
            e.serviceStar = e.serviceStar || {}
            return {
              ...e,
              status: surveyStatusMap[e.status],
              serviceStarName: e.serviceStar.name,
              serviceStarTel: e.serviceStar.tel
            }
          })
          this.dataList = [...dataList, ...this.dataList]
          this.suvOrderNumList = this.dataList.map(item => item.orderNo)
          this.total = res.data.total
          this.finished = this.dataList.length === res.data.total
          this.error = false
        } else {
          this.error = true
        }
      }).catch(() => {
        Toast.clear()
        this.error = true
      }).finally(() => {
        Toast.clear()
        this.loading = false
      })
    }
  }
}
</script>
<style lang="less" scoped>
.list {
  .card-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      width: 500px;
      margin-right: 20px;
    }

    .tag {
      display: inline-block;
      padding: 0 10px;
      color: @blue;
      font-size: 12px;
      height: 42px;
      line-height: 42px;
    }
  }
  .info {
    font-size: 24px;
    color: #666;
    div {
      height: 34px;
    }
  }
}
</style>
